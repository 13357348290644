import React from "react";
import { getWPData, REBUILD_TIMEOUT } from "../lib";
import { WidgetPage } from "../widgets";

export default function Home(props) {
  return (
    <>
      <WidgetPage {...props} background={false} />
    </>
  );
}

export async function getStaticProps() {
  const data = await getWPData("pages/2");

  return {
    revalidate: REBUILD_TIMEOUT,
    props: {
      data,
      layoutProps: {
        isLightHeader: true,
        isWhiteBody: false,
      },
    },
  };
}
